/* some weird semantic styles override */
.ui.card:first-child {
	margin: 1em 0;
}

.videoCard {
	box-shadow: none !important;
}

.ui.checkbox {
	margin: 0.4em 0.4em 0.4em 0.4em !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:after {
	background-color: #00b396;
	color: white !important;
}

.ui.fluid.card {
	border-radius: 2%;
}

.standards-modal {
	background: orange;
	position: relative;

	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;

	margin: 0 !important;
}
