.home-row-dark {
	background-color: #090808 !important;
	color: rgba(255, 255, 255, 0.8);
}

.home-row-light {
	background-color: #e6e6ed !important;
}

.header-container-column {
	position: relative;
}
