@media only screen and (min-width: 560px) and (max-height: 740px) {
    /* .bitmovinplayer-container.aspect-16x9 {
        width: 75% !important;
    } */
}
@media only screen and (max-width:768px){
    .bitmovinplayer-container.aspect-16x9 {
        border-radius: 3% !important;
    }
}
