
/* Avenir, normal, 300 */
@font-face {
	font-family: 'Avenir';
    font-weight: 300;
    font-style: normal;
	src: url('Fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix');
	src: url('Fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix') format('eot'), url('Fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2') format('woff2'),
		url('Fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff') format('woff'), url('Fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf') format('truetype');
}

/* Avenir, normal, 500 */
@font-face {
	font-family: 'Avenir';
    font-weight: 500;
    font-style: normal;
	src: url('Fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix');
	src: url('Fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix') format('eot'), url('Fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2') format('woff2'),
		url('Fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff') format('woff'), url('Fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf') format('truetype');
}

/* Avenir, normal, 800 */
@font-face {
	font-family: 'Avenir';
    font-weight: 800;
    font-style: normal;
	src: url('Fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix');
	src: url('Fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix') format('eotave'), url('Fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2') format('woff2'),
		url('Fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff') format('woff'), url('Fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf') format('truetype');
}

/* Avenir, normal, 900 */
@font-face {
	font-family: 'Avenir';
    font-weight: 900;
    font-style: normal;
	src: url('Fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix');
	src: url('Fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix') format('eot'), url('Fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2') format('woff2'),
		url('Fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff') format('woff'), url('Fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf') format('truetype');
}
