/*
    #root - this is the root node the app is mounted on
 */
#root {
	background: #7569D6;
	position: absolute;
	z-index: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

/*
    .app-container - this is the application node mounted on root
 */

.app-container {
}
