.rich-text {

}

.rich-text-text {
    text-wrap: balance;
}

.rich-text-math {
    .katex {
        white-space: nowrap !important;
    }
}

.rich-text-error {
    color: red;
}
