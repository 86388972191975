// @tailwind base;
// @tailwind components;
// @tailwind utilities;

// @layer utilities {
//     .error-text {
//         font-size: 3rem;
//         color: red;
//         background: yellow;
//         border: 1px solid red;
//     }
// }

.bmpui-ui-titlebar {
    background: none;
}

//.bmpui-ui-controlbar {
//    background: none;
//}

//input:-internal-autofill-selected {
//    appearance: 'menulist-button',
//    backgroundImage: 'none !important',
//    backgroundColor: '-internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important',
//    color: 'fieldtext !important'
//}

/* this is to replace the input autofill color */
input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
}

#login-form {
    /* this is to replace the input autofill color */
    input:-webkit-autofill, input:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
        -webkit-box-shadow: 0 0 0 1000px #5a38ab inset;
    }
}

#onboard-stage-agree-to-terms {

}

.hide-input {
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}
